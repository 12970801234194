import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="App-title">
          쿠마컴퍼니
        </p>
        <a className="App-link"
          href="https://s3.ap-northeast-2.amazonaws.com/kumacompany.kr/privacy_policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          개인정보이용방침
        </a>

        <a className="App-link" href="mailto:contact@warmup.kr">Email</a>
      </header>
    </div>
  );
}

export default App;
